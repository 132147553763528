<div id="modal-content-wrapper">
  <header id="modal-header">
      <h4 class="numberstitle" id="modal-title">{{ modalData.title }} 
      <br/> {{ modalData.present }}</h4>
  </header>
  <div class="filocolorato"></div>
  <section id="modal-body">
    <div class="text-block text-blu text-center">
      {{ modalData.message }}
    </div>
    
  </section>
  <footer id="modal-footer">
   <!-- <a class="cancel-button" (click)="closeModal()">
      {{ modalData.cancelButtonText }}
    </a>-->
    <a (click)="actionFunction()">
      {{ modalData.actionButtonText }}
    </a>
    
    
  </footer>
</div>
