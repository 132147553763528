<button mat-icon-button class="close-button" (click)="closeModal()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div id="modal-content-wrapper">
    <header id="modal-header">
        <h4 class="numberstitle" id="modal-title">{{ modalData.title }} 
        <br/> {{ modalData.present }}</h4>
    </header>
    <div class="filocolorato"></div>
    <section id="modal-body">
      <div class="text-block text-blu text-center">
        {{ modalData.messagePlain }} <br><b>{{modalData.messageBold}}</b> <br> 
        <!-- <a class="link-modal" (click) = "actionFunction()" routerLink="/profile"> CLICCA QUI </a> -->
      </div>
      
    </section>
    <footer id="modal-footer">
     <!-- <a class="cancel-button" (click)="closeModal()">
        {{ modalData.cancelButtonText }}
      </a>-->
      
      
    </footer>
  </div>
  