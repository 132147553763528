import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalActionsService } from '../modal/modal-actions.service';

@Component({
  selector: 'app-modal-update',
  templateUrl: './modal-update.component.html',
  styleUrls: ['./modal-update.component.scss']
})
export class ModalUpdateComponent implements OnInit {

  public ok = false;

  constructor(public dialogRef: MatDialogRef<ModalUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private modalService: ModalActionsService,
    @Inject(DOCUMENT) private document: Document) {
}

ngOnInit() {
}

actionFunction() {
this.modalService.modalAction(this.modalData);
this.closeModal();
}

closeModal() {
this.ok = true;
this.dialogRef.close();
this.document.defaultView.location.reload();
}

}
