import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year = ''
  prod = false;

  constructor() {
    this.prod = environment.production;
  }

  ngOnInit() {
    this.year = new Date().getFullYear().toString();
  }

  
}
