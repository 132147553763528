<div id="modal-content-wrapper">
    <header id="modal-header">
        <h4 class="numberstitle" id="modal-title">Complimenti!
        </h4>
    </header>
    <div class="filocolorato"></div>
    <section id="modal-body">
      <div class="text-block text-blu text-center">
        
        {{modalData.message}}
      </div>
      
    </section>
    <footer id="modal-footer"  >
        <a (click)="closeModal()">
          <b>OK</b>
        </a>
        
        
      </footer>
  </div>
  